.scrollTopBtn {
    width: 45px;
    height: 45px;
    position: fixed;
    right: 20px;
    bottom: 20px;
    transform: translateY(100px);
    z-index: 100;
    background-color: $color-third;
    border-width: $border-width;
    border-color: $color-third;
    border-radius: 50%;
    padding: 0;
    margin: 0;
    opacity: 0;
    transition: all $duration ease;

    &--show,
    &--show:focus  {
        opacity: 1;
        transform: translateY(0);
    }

    svg {
        path {
            fill: $color-text;
            transition: $duration;
        }
    }

    &:hover,
    &:focus {
        background: $color-white;
        border-color: $color-third;

        svg path {
            fill: $color-text;
        }
    }
}