header {
    position: relative;
    z-index: 15;
}

.header-top {
    .header__identity {
        display: flex;
        align-items: center;
        justify-content: center;

        .identity {
            display: block;
        }

        &__title {
            display: block;
            margin: 0;
            padding: 45px 0 35px 0;
            color: $color-text;
            line-height: 0;
        }
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

}

.header-bottom {
    &__container {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $color-white;
        border-radius: 30px;
        box-shadow: $shadow;
    }
}

//======================================================================================================
// Tools
//======================================================================================================
#uci_link {
    display: none;
}

.tools {
    position: relative;
    display: block;
    margin-right: 50px;

    &__content {
        position: relative;
        display: flex;
        justify-content: center;
    }

    &__search {
        &--disabled {
            pointer-events: none;
            opacity: .2;
        }
    }

    .tool {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 0 0 14px;
        padding: 0;
        background-color: $color-bg--transparent;
        border: 0;
        border-radius: 0;
        font-size: $font-size--text-small;
        font-weight: $font-weight-bold;
        color: $color-text;
        text-decoration: none;
        transition: none;

        &__title {
            position: relative;
            margin-left: 7px;
            font-weight: $font-weight-bold;
            font-size: $font-size--text-small;

            &::after {
                content: "";
                position: relative;
                display: block;
                bottom: -3px;
                left: 0;
                width: 0;
                height: 2px;
                background-color: $color-third;
                transition: width $duration ease-in-out;
            }
        }
        
        &__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            padding: 0;
            background-color: $color-third;
            border: $border-style $border-width $color-third;
            border-radius: $border-radius--round;
            transition: background-color $duration ease-in-out;
        }

        svg {
            width: 40px;
            height: 40px;
            margin: 0;
            padding: 0;
            fill: $color-text;
        }

        &:hover, &:focus {
            cursor: pointer;
            
            .tool {
                &__icon {
                    background-color: $color-bg--transparent;
                }
                &__title {
                    &::after {
                        width: 100%;
                    }
                }
            }
        }

        &:first-child {
            margin-left: 0;
        }

    }

    &__home {
        position: relative;
        display: none;
        align-items: center;
        justify-content: center;
        width: 0;
        height: 44px;
        border-radius: $border-radius--round;
        background-color: $color-third;
        border: $border-style $border-width $color-third;
        opacity: 0;
        transition: opacity ease-in-out $duration;
        margin-right: 25px;

        svg {
            width: 40px;
            height: 40px;
            fill: $color-text;
        }

        &:hover, &:focus {
            background-color: $color-bg--transparent;
        }
    }

}

.tools-view {
    position: absolute;
    top: 165px;
    width: 100%;
    z-index: 1;

    .container--tools-view {
        display: flex;
        justify-content: flex-end;

        #google_translate_element {
            display: none;
        }

        .profils {
            position: relative;
            display: none;
            width: 240px;
            height: auto;
            margin-top: 45px;
            margin-right: 60px;
            padding: 15px 20px 20px;
            background: $color-second;

            &.show-list {
                display: flex;
                flex-direction: column;
            }

            &__title {
                color: $color-white;
                text-transform: uppercase;
                margin: 20px 0 15px;
            }

            &__list {
                li {
                    a {
                        position: relative;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        padding: 10px 20px 10px 0px;
                        border-top: 1px solid $color-white;
                        color: $color-white;
                        transition: all $duration;

                        &:hover {
                            padding-left: 10px;
                        }

                        &::after {
                            content: "\f344";
                            font: normal 13px/1 dashicons;
                            display: flex;
                            align-items: center;
                            position: absolute;
                            right: 0;
                            height: 100%;
                            color: $color-white;
                        }
                    }

                    &:last-child {
                        a {
                            border-bottom: 1px solid $color-white;
                        }
                    }
                }
            }
        }
    }
}

iframe.goog-te-menu-frame.skiptranslate {
    position: fixed;
    top: 230px !important;
    left: calc(100% - ((100% - 1200px) / 2) - 335px) !important;
}


//======================================================================================================
// Header Fixed
//======================================================================================================
.header.fixed {

    .header-bottom {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 98;
        box-shadow: 0px 0px 1px rgba(37, 129, 149, 0.08), 1px 1px 20px rgba(37, 129, 149, 0.2);
        background-color: $color-white;

        &__container {
            border-radius: 0;
            box-shadow: none;

            &::before, &::after {
                content: '';
                display: block;
                position: absolute;
                width: 50vw;
                top: 0;
                bottom: 0;
                background-color: $color-white;
                z-index: 0;
            }

            &::before {
                left: -50vw;
            }

            &::after {
                right: -50vw;
            }
        }

        .tools {
            margin-right: 0;
        }
    
        .tools__home {
            display: flex;
            width: 44px;
            opacity: 1;
            transition: all ease-in-out $duration;
        }

        #nav-main {
            .menu {
                align-items: center;
            }

            .menu__nav__list {
                padding-right: 20px;
            }

            .nav-main-item:first-of-type .nav-main-link {
                border-radius: 0;
            }

            .nav-main-link {
                padding: 33px 29px;
            }
        }
    }
}

.admin-bar .header.fixed {
    .tools__search, .header-bottom {
        top: 32px;
    }
} 


//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        background-color: $btn-privacy-bg;
        border-color: $btn-privacy-border-color;
        color: $btn-privacy-color;

        &:hover, &:focus {
            background-color: $btn-privacy-bg--hover;
            border-color: $btn-privacy-border-color--hover;
            color: $btn-privacy-color--hover; 
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .tools {
        margin: 0 15px;

        .tool__title {
            display: none;
        }
    }

    .header.fixed {

        .tools__home {
            margin-right: 18px;
        }

        .header-bottom #nav-main {
            .menu__nav__list {
                padding-right: 0;
            }
    
            .nav-main-link {
                padding: 29px 14px;
            } 
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .header-bottom__container {
        width: fit-content;
        margin: 0 auto;
        height: 80px;
        overflow: hidden;
    }

    .tools {
        margin: 30px;

        .tool {
            margin: 0 0 0 15px;
        }
    }

    .privacy {
        &__container {
            flex-wrap: wrap;
        }

        &__button {
            margin-left: 0;
            margin-top: 20px;
        }
    }
  
}


// 640
@media screen and (max-width: $small) {

    .header-top {
        .header__identity {
            &__title {
                svg {
                    width: 300px;
                    height: 35px;
                }
            }
        }
    }

    .header-bottom__container {
        width: 300px;
        justify-content: center;
    }

    .tools {
        margin: 25px;
    }

}
