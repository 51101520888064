//======================================================================================================
// Style Home
//======================================================================================================
.home {

    &__header {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        width: fit-content;
        margin-bottom: 30px;
    }

    &__title {
        margin: 0;
        padding: 0;
        text-align: center;
        font-size: $font-size--1;
        font-weight: $font-weight-light;
        color: $color-text;

        &::after {
            display: none;
        }
    }

    &__subtitle {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-family: 'Caveat', cursive;
        font-size: 2.500rem;
        line-height: 1; 
        
        &::before {
            content: '';
            width: 50px;
            height: 3px;
            margin: 10px 8px 0 0;
            border-radius: $border-radius;
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 40px;
    }

    &__button {
        margin: 0;
        padding: 14px 75px !important;

        svg {
            width: 26px;
            height: 26px;
            margin-right: 8px;
        }

        &:hover, &:focus {
            svg {
                transform: rotate(90deg);
            }
        }
    }


    //======================================================================================================
    // SlideShow
    //======================================================================================================
    &-slideshow {
        position: relative;
        overflow: hidden;
        width: 100%;
        margin-top: -50px;
    
        .swiper-container {
            height: 550px;
            width: 100%;
            z-index: inherit;
    
            .swiper-slide {
                img {
                    position: absolute;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    object-position: top;
                }
            }

            .pagination-container {
                position: relative;
            }
    
            .container--pagination {
                position: absolute;
                display: flex;
                align-items: center;
                left: 0;
                bottom: 55px;
                height: 40px;
                width: 100px;
                pointer-events: none;

                .swiper-button {
                    pointer-events: auto;
                    width: 40px;
                    height: 40px;
                    background-color: rgba(255, 255, 255, 0.8);
                    border: $border-width $border-style $color-bg--transparent;
                    border-radius: $border-radius--round;
                    transition: all $duration ease-in-out;

                    &::after {
                        display: none;
                    }

                    svg {
                        width: 40px;
                        height: 40px;
                        fill: $color-text;
                    }

                    &:hover, &:focus {
                        background-color: $color-white;
                        border-color: $color-dark;
                    }
                }

                .swiper-button-prev {
                    left: 0;
                }

                .swiper-button-next {
                    right: 0;
                }
            }
        }
    
        .slideshow {
            display: inherit;
            position: relative;
            width: 100%;
            height: 100%;

            .container--slideshow {
                position: relative;
                display: flex;
                justify-content: center;
                height: 100%;
                background-color: transparent !important;
            }

            &__content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                box-sizing: border-box;
                position: absolute;
                max-width: 570px;
                height: auto;
                right: 15px;
                bottom: 50px;
                padding: 13px 30px;
                background-color: $color-second--rgba;
                border-radius: 50px;
                text-align: right;
                color: $color-text;
            }

            &__title {
                font-family: $font-family--heading;
                font-weight: $font-weight-semibold;
                line-height: 1.3;
                font-size: 1.125rem;
            }
        }

        .video-container {
            height: 550px;
            width: 100%;
    
            .slideshow {
                display: inherit;
                position: relative;
                width: 100%;
                height: 100%;

                video,
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    object-position: top;
                }

                img {
                    display: none;
                }
            }
        }
    }


    //======================================================================================================
    // Access
    //======================================================================================================
    .access {
        padding: 50px 0 70px 0;

        &__list {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 30px;

            &::after {
                content: '';
                position: absolute;
                z-index: -1;
                left: 0;
                right: 0;
                width: 100%;
                height: 120px;
                background-color: $color-bg--neutral;
                border-radius: $border-radius;
            }
        }

        &__link {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 160px;
            height: 170px;
            background-color: $color-white;
            border-radius: 40px;
            box-shadow: $shadow;

            span {
                margin-top: 5px;
                font-size: $font-size--text-small;
                font-weight: $font-weight-bold;
                color: $color-text;
            }

            svg path {
                transition: fill $duration ease-in-out;
            }

            &:hover, &:focus {
                background-color: $color-third;
                box-shadow: $shadow--hover;

                svg path[fill="#89D7E7"] {
                    fill: $color-white;
                }
            }
        }

    }


    //======================================================================================================
    // News (posts)
    //======================================================================================================
    .news {
        position: relative;
        padding: 0 0 50px 0;

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -2;
            width: 100%;
            height: 560px;
            background: $color-gradient;
            transform: rotate(-180deg);
        }

        &__container {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                bottom: -95px;
                right: 515px;
                z-index: -1;
                width: 1500px;
                height: 225px;
                background: url(/wp-content/themes/bon-repos-sur-blavet/assets/src/images/sprite-images/theme/filigrane_pont.svg) no-repeat bottom;
                background-size: contain;
            }
        }

        &__subtitle {
            color: $color-second;
            
            &::before {
                background-color: $color-second;
            }
        }

        .swiper {
            &-wrapper {
                display: flex;
                align-items: stretch;
            }

            &-slide {
                overflow: hidden;
                width: 370px;
                margin: 0 30px 0 0;
                border-radius: $border-radius;
                transition: box-shadow $duration ease-in-out;

                &:hover, &:focus {
                    transition: box-shadow $duration ease-in-out;

                    .news__infos {
                        background-color: $color-bg--neutral;
                        transition: background-color $duration ease-in-out;
                    }
                }
            }
    
            &-container {
                padding: 15px;
                margin: -15px;
                filter: drop-shadow(1px 1px 20px rgba(37,129,149,.28));
            }
        }

        &__navigation {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: space-between;
            right: 15px;
            left: auto;
            top: 60px;
            bottom: auto;
            height: 42px;
            width: 100px;
            pointer-events: none;

            .swiper-button {
                pointer-events: auto;
                width: 42px;
                height: 42px;
                background-color: $color-second;
                border: $border-width $border-style $color-second;
                border-radius: $border-radius--round;
                transition: all $duration ease-in-out;

                &::after {
                    display: none;
                }

                svg {
                    width: 100%;
                    height: 100%;
                    fill: $color-text;
                    transition: all ease-in-out $duration;
                }

                &:hover, &:focus {
                    background-color: $color-bg--transparent;
                }
            }

            .swiper-button-prev {
                left: 0;
            }

            .swiper-button-next {
                right: 0;
            }
        }

        &__link {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: stretch;
            width: 100%;
            color: $color-text;

            &:hover, &:focus {
                background-color: $color-bg--neutral;
                box-shadow: $shadow--hover;

                img {
                    transform: scale(1.1);
                }
            }

        }

        &__content {
            overflow: hidden;
            line-height: 0;
            height: 240px;

            &--notimg {
                background: url(/wp-content/themes/noyau/assets/images/icon-post.png) no-repeat $color-bg--image;
                background-position: center;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top center;
                transform: scale(1);
                transition: transform 0.75s ease-in-out;
            }
        }

        &__infos {
            box-sizing: border-box;
            height: 220px;
            padding: 20px 30px;
            background-color: $color-white;
            text-align: left;
            transition: background-color $duration ease-in-out;

            &__title {
                margin: 0;
                padding: 0;
                font-size: $font-size--4;

                &::after {
                    display: none;
                }
            }

            &__introduction {
                margin-top: 10px;
            }
        }

        &__button {
            background-color: $color-white;
            color: $color-main;
            border-color: $color-white;

            svg {
                fill: $color-main;
            }

            &:hover, &:focus {
                background-color: $color-bg--transparent;
                border-color: $color-white;
                color: $color-text;

                svg {
                    fill: $color-text;
                }
            }
        }
  
    }


    //======================================================================================================
    // Docs
    //======================================================================================================
    .docs {
        position: relative;
        padding: 70px 0 110px 0;
        background-color: $color-bg--neutral;

        &__subtitle {
            color: $color-main;
            
            &::before {
                background-color: $color-main;
            }
        }

        &__list {
            display: flex;
            align-items: flex-start;
        }

        &__item {
            position: relative;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            z-index: 1;
            padding: 30px 0;

            &--big {
                width: 630px;
                height: 440px;
                margin-right: 40px;

                &::after {
                    content: '';
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 70px;
                    background-color: $color-white;
                    border-radius: $border-radius;
                }

                .docs {
                    &__image {
                        width: 270px;
                        height: 380px;
                    }

                    &__content {
                        width: 270px;
                        margin-left: 40px;

                        &__title {
                            font-size: $font-size--3;
                        }
                    }

                }
            }

            &--small {
                width: 500px;
                height: 305px;
                flex-direction: row-reverse;

                &::after {
                    content: '';
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    right: 85px;
                    bottom: 0;
                    left: 0;
                    background-color: $color-white;
                    border-radius: $border-radius;
                }

                .docs {
                    &__image {
                        width: 175px;
                        height: 245px;
                    }

                    &__content {
                        width: 200px;
                        margin-right: 65px;

                        &__title {
                            font-size: $font-size--4;
                        }

                        &__buttons {
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                        }
                    }

                    &__btn {
                        margin: 0 15px 0 0;

                        span {
                            display: none;
                        }
                    } 
                }
                
                .link-document svg {
                    margin-right: 0;
                }
            }
        }

        &__image {
            display: block;
            background-color: $color-main;
            overflow: hidden;
            border-radius: 5px;
            box-shadow: $shadow;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
            }

            &--no-image {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $color-bg--image;

                svg {
                    width: 40%;
                }
            }
        }

        &__content {
            display: block;

            &__title {
                margin: 0 0 8px 0;
                padding: 0;
    
                &::after {
                    display: none;
                }
            }

            &__buttons {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-top: 15px;
            }
        }

        &__date {
            display: block;
        }
        
        &__filesize {
            display: block;
            text-transform: uppercase;
        }

        &__btn {
            margin: 15px 0 0 0;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;
            text-decoration: none;

            svg {
                width: 40px;
                height: 40px;
            }

            &:hover, &:focus {
                svg {
                    background-color: $color-bg--transparent;
                    fill: $color-main;
                }
            }
        }

        &__buttons {
            justify-content: flex-start;
            margin: 40px 40px 0 0;
        }

        &__button {
            background-color: $color-main;
            border-color: $color-main;
            color: $color-white;

            svg {
                fill: $color-white;
            }

            &:hover, &:focus {
                background-color: $color-bg--transparent;
                color: $color-text;

                svg {
                    fill: $color-text;
                }
            }
        }

    }


    //======================================================================================================
    // Newsletter
    //======================================================================================================
    .newsletter {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: 500px;
        height: 195px;
        border-radius: $border-radius;
        box-shadow: $shadow;
        background-color: $color-white;
        overflow: hidden;
        color: $color-text;

        &:hover, &:focus {
            background-color: $color-third;
            box-shadow: $shadow--hover;
            color: $color-text;

            .newsletter__content svg path[fill="#89D7E7"] {
                fill: $color-white;
            }

            .newsletter__link {
                background-color: $color-white;
                transition: background-color $duration ease-in-out;
            }
        }

        &__container {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            margin-top: -95px;
        }

        &__content {
            display: flex;
            align-items: center;
            padding: 27px 30px;

            svg {
                flex: none;
                margin-right: 30px;
                width: 80px;
                height: 80px;

                path[fill="#89D7E7"] {
                    transition: fill $duration ease-in-out;
                }
            }
        }

        &__title {
            margin: 0;
            padding: 0;
            font-size: $font-size--3;

            &::after {
                display: none;
            }
        }

        &__link {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 17px;
            background-color: $color-third;
            font-weight: $font-weight-bold;
            transition: background-color $duration ease-in-out;

            svg {
                width: 26px;
                height: 26px;
                margin-right: 15px;
            }
        }

    }


}



//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .home {

        // style home
        &__button {
            padding: 14px 37px!important;
        }

        // slideshow
        &-slideshow {
            .swiper-container {
                height: 440px;
    
                .container--pagination {
                    bottom: 45px;
                }
                
            }
    
            .slideshow__content {
                bottom: 40px;
                right: 10px;
            }

            .video-container {
                height: 440px;
            }
        }

        // access
        .access {
            padding: 40px 0 60px;

            &__list {
                padding: 0;
            }

            &__link {
                width: 140px;
            }
        }

        //news
        .news {
            .swiper-slide {
                width: 300px;
                margin: 0 20px 0 0;
            }

            &__container::after {
                bottom: -61px;
                right: 385px;
                width: 1200px;
                height: 180px;
            }

            &__infos {
                padding: 20px;
            }
        }

        // docs
        .docs  {
            padding: 60px 0 70px;

            &__item {
                &--big {
                    width: 480px;
                    height: 410px;

                    .docs__image {
                        width: 230px;
                        height: 325px;
                    }

                    .docs__content {
                        width: 210px;
                        margin-left: 20px;
                    }
                }

                &--small {
                    width: 420px;
                    height: 270px;

                    .docs__image {
                        width: 150px;
                        height: 210px;
                    }

                    .docs__content {
                        width: 200px;
                        margin-right: 40px;
                    }

                    .docs__btn {
                        margin-top: 10px;
                    }
                }
            }

            &__content__buttons {
                margin-top: 5px;
            }

            &__btn {
                margin: 10px 0 0;
            }
        }

        // newsletter 
        .newsletter {
            width: 420px;
            height: 185px;

            &__content {
                padding: 26px 15px 18px 15px;

                svg {
                    margin-right: 10px;
                }
            }
        }

    }
    


}


// 960
@media screen and (max-width: $medium) {

    .home {

        // style home

        // slideshow
        &-slideshow {
            margin-top: -40px;
            
            .swiper-container {
                height: 350px;
            }
    
            .video-container {
                height: 350px;

                .slideshow {
                    video {
                        display: none;
                    }
    
                    img {
                        display: block;
                    }
                }
            }
        }

        // access
        .access {
            padding: 25px 0 45px;

            &__list {
                flex-wrap: wrap;
                padding: 0 25px;

                &::after {
                    height: 320px;
                }
            }

            &__link {
                width: 170px;
                margin: 15px 0;
            }
        }

        //news
        .news {
            &__container::after {
                right: 125px;
            }
        }
        

        // docs
        .docs {
            &__list {
                flex-wrap: wrap;
            }

            &__item {
                &--big {
                    width: 100%;
                    height: 390px;
                    margin: 0 0 40px 0;

                    .docs__content {
                        width: 330px;
                        margin-left: 30px;
                    }
                }

                &--small {
                    width: 100%;
                    height: 195px;
                    padding: 30px;

                    &::after {
                        right: 0;
                    }

                    .docs__content {
                        width: 100%;
                        margin-right: 0;
                    }

                    .docs__image {
                        display: none;
                    }
                }
            }

            &__buttons {
                margin: 40px 0 40px 0;
            }
        }


        // newsletter 
        .newsletter {
            width: 100%;

            &__container {
                flex-direction: column;
                margin-top: 0;
            }

            &__content {
                justify-content: center;
            }

            &__infos {
                width: 315px;
            }
        }

    }

}


// 640
@media screen and (max-width: $small) {

    .home {

        // style home
        &__header {
            justify-content: center;
            align-items: center;
            width: 100%;
        }

        &__buttons {
            width: 100%;
        }

        &__button {
            width: 100%;
            padding: 14px 30px!important;
        }

        // slideshow
        &-slideshow {
            .swiper-container {
                height: 285px;

                .container--pagination {
                    bottom: 20px;
                }
            }

            .video-container {
                height: 285px;

                .slideshow__content {
                    bottom: 20px;
                }
            }

    
            .slideshow  {
                &__content {
                    bottom: 70px;
                    left: 10px;
                    max-width: 220px;
                    text-align: left;
                    padding: 12px 25px;
                }

                &__title {
                    font-size: $font-size--text-small;
                }
            }
        }

        // access
        .access {
            padding: 30px 0 60px;

            &__list {
                padding: 0;

                &::after {
                    height: 500px;
                    width: 255px;
                    margin: 0 auto;
                }
            }

            &__link {
                width: 140px;
                margin: 10px;
            }
        }

        //news
        .news {
            &__header {
                margin-bottom: 80px;
            }

            &__navigation {
                right: 0;
                left: 0;
                top: 118px;
                margin: 0 auto;
            }
    
            &__container:after {
                right: -435px;
                bottom: -65px;
            }
        }

        // docs
        .docs {
            &__item {
                &--big {
                    width: 100%;
                    height: auto;
                    padding: 0 0 25px 0;
                    margin: 0 0 30px;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: center;

                    &::after {
                        top: 25px;
                        left: 0;
                    }

                    .docs__image {
                        width: 250px;
                        height: 355px;
                    }

                    .docs__content {
                        width: 250px;
                        margin-left: 0;
                        margin-top: 20px;
                    }
                }

                &--small {
                    width: 100%;
                    height: auto;
                    padding: 25px;
                }
            }
        }

        // newsletter 
        .newsletter {
            height: auto;

            &__content {
                justify-content: center;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 20px 25px;

                svg {
                    margin-right: 0;
                }
            }

            &__infos {
                width: auto;
                text-align: center;
                margin-top: 20px;
            }

            &__title {
                margin-bottom: 10px;
            }
        }
    }

}
