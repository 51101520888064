.footer {
     display: flex;
     flex-direction: column;
    
     &__title {
          font-family: $font-family--heading;
          font-size: $font-size--4;
          font-weight: $font-weight-bold;
     }

     &__subtitle {
          font-family: $font-family--heading;
          font-size: $font-size;
          font-weight: $font-weight;
          margin-bottom: 5px;
     }

     &__top .container {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          padding: 70px 0;
     }

     &__logo {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 210px;

          &__contact {
               padding: 15px 27px !important;
               margin: 20px 0 0 0;

               svg {
                    margin: 0 5px 0 0;
               }
          }
     }

     &__infos {
          width: 580px;
          border: 1px $border-style $color-second--light;
          border-radius: 5px;
          overflow: hidden;

          &__container {
               display: flex;
               align-items: center;
               flex-direction: column;
               flex-wrap: wrap;
               width: 100%;
               height: 280px;
          }

          &__item {
               position: relative;
               display: flex;
               align-items: center;
               justify-content: center;
               width: 180px;
               height: 33.334%;
               cursor: pointer;
               border-right: 1px $border-style $color-second--light;
               border-bottom: 1px $border-style $color-second--light;

               &.visible {
                    background-color: $color-second;

                    &::after {
                         content: '';
                         width: 0;
                         border-top: 12px solid transparent;
                         border-bottom: 12px solid transparent;
                         border-left: 10px solid $color-second;
                         position: absolute;
                         right: -10px;
                         top: 50%;
                         transform: translateY(-50%);
                    }
               }

               &--3 {
                    border-bottom: 0;
               }

               .footer__title {
                    text-align: center;
               }
          }

          &__content {
               display: none;
               width: 400px;
               height: 100%;
               padding: 20px 20px 20px 30px;
               background-color: $color-second--extralight;

               p {
                    margin-bottom: 15px;

                    > span {
                         display: block;
                    }

                    &:last-of-type {
                         margin-bottom: 0;
                    }
               }

               &__title {
                    margin-bottom: 0 !important;
                    font-weight: $font-weight-bold;
               }

               &.visible {
                    display: block;
                    order: 3;
               }
          }

          &__phone {
               &:hover, &:focus {
                    text-decoration: underline;
               }
          }
     }

     &__contact {
          width: 270px;

          &__title {
               margin-top: 20px;
               font-weight: $font-weight-bold;
          }
     }



     &__bottom {
          padding: 30px 0;
          background-color: $color-bg--neutral;
     }

     &__menu {
          ul {
               display: flex;
               justify-content: center;
               align-items: center;
               flex-wrap: wrap;
               width: 100%;
          }

          li {
               position: relative;
               padding: 0 35px;

               &:first-child {
                    padding-left: 0;
               }

               &:last-child {
                    padding-right: 0;
               }

               a {
                    font-size: 0.875rem;
               
                    &:hover, &:focus {
                         color: $color-main;
                         text-decoration: underline;
                    }
               } 
          }
     }

}





// 1200
@media screen and (max-width: $large) {

     .footer {
          &__logo {
               width: 190px;

               &__contact {
                    padding: 15px 17px!important;
               }
          }

          &__infos {
               width: 435px;

               &__item {
                    width: 165px;
               }

               &__container {
                    height: 330px;
               }

               &__content {
                    width: 270px;
                    padding: 20px;
               }
          }

          &__contact {
               width: 250px;
          }

          &__menu li {
               padding: 0 20px;
          }
      }

}

// 960
@media screen and (max-width: $medium) {

     .footer {
          &__top .container {
               padding: 60px 0 50px 0;
               flex-wrap: wrap;
               justify-content: center;
          }

          &__logo {
               width: 190px;
               margin-right: 50px;
          }

          &__infos {
               order: 3;
               width: 620px;
               margin-top: 60px;

               &__container {
                    height: 260px;
               }

               &__content {
                    width: 455px;
                    padding: 20px;
               }
          }

          &__bottom {
               padding: 15px 0;
          }

          &__menu li {
               padding: 15px 20px;
          }
     }

}

// 640
@media screen and (max-width: $small) {

     .footer {

          &__logo {
               margin-right: 0;
          }

          &__infos {
               order: inherit;
               margin-top: 50px;

               &__container {
                    width: 100%;
                    height: auto;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: nowrap;
               }

               &__item {
                    width: 100%;
                    height: 64px;
                    border-right: 0;

                    &.visible::after {
                         border-top: 12px solid #89e093;
                         border-bottom: 12px solid transparent;
                         border-left: 14px solid transparent;
                         border-right: 14px solid transparent;
                         right: 0;
                         left: 0;
                         margin: 0 auto;
                         top: inherit;
                         bottom: -36px;
                    }
               }

               &__content {
                    width: 100%;
                    height: auto;

                    &.visible {
                         order: inherit;
                         width: 100%;
                         height: auto;
                    }
               }
          }

          &__contact {
               margin-top: 50px;
               text-align: center;
          }

          &__menu li {
               padding: 15px 12px;
          }
     }

}