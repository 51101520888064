.chatbot{
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 570px;
  background: transparent;
  border-radius: $chatbot-radius;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  filter: $chatbot-shadow;
  transition: all 0.4s;

  &.collapse{
    width: 80px;
    height: 80px;
    transition: right 0.4s;

    .chatbot__header{
      width: 100%;
      height: 100%;
      padding: 0;
      border-radius: 50%;

      .chatbot__identity {
        width: 100%;
        height: 100%;
      }
      .chatbot__avatar {
        width: 100%;
        height: 100%;
        margin: 0;
        background-color: $color-white;
        border: 2px solid $chatbot-header-bg;
      }
      .chatbot__title {
        display: none;
      }

      .chatbot__reduction {
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 1;
        position: absolute;
        pointer-events: all;

      }
    }
    .chatbot__container{
      display: none;
    }
    .chatbot__footer{
      display: none;
    }

    .chatbot__notification {
      display: flex;
      position: absolute;
      width: 24px;
      height: 24px;
      top: 0;
      right: 0;
      background: #E23D3D;
      color: $color-white;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: $font-size--text-small;
      font-weight: $font-weight-extrabold;
      transition: opacity 0.25s;

      &.hidden{
        opacity: 0;
      }
    }
  }

  &.chatbot--left{
    right: 80px;
    transition: right 0.4s;
  }

  .button-chatbot{
    background: transparent;
    margin: 0;
    border: 0;
    padding: 0;
    border-radius: inherit;
  }

  &__header {
    display: flex;
    width: 100%;
    height: 70px;
    background: $chatbot-header-bg;
    border-top-right-radius: $chatbot-radius;
    border-top-left-radius: $chatbot-radius;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
  }
  &__identity {
    display: flex;
    align-items: center;
  }
  &__avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    background-image: url(/wp-content/themes/noyau/assets/images/chatbot/avatar-bulles.svg);
    background-size: 100%;
    background-color: $color-light;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;

    &.woman{
      background-image: url(/wp-content/themes/noyau/assets/images/chatbot/avatar-femme.svg);
    }
    &.man{
      background-image: url(/wp-content/themes/noyau/assets/images/chatbot/avatar-homme.svg);
    }
    &.robot{
      background-image: url(/wp-content/themes/noyau/assets/images/chatbot/avatar-robot.svg);
    }
    &.custom{
      background:$color-white;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
  &__title{
    p{
      font-size:$font-size--5;
      text-transform: uppercase;
      color:$color-white;
      font-family: $font-family--heading;
    }
  }
  button.chatbot__reduction {
    display: flex;
    width: 35px;
    height: 35px;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius;
    background: rgba(255, 255, 255, 0.1);
    transition: background $duration;

    &:hover,
    &:active{
      background: $color-light;

      svg path{
        stroke:$color-main;
      }
    }

    svg {
      width: 11px;
      transition: all $duration;
    }
  }
  &__notification{
    display: none;
  }
  &__container {
    display: flex;
    width: 100%;
    height: calc(100% - 140px);
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 15px 5px;
    background: $chatbot-content-bg;
    height: max-content;
    width: 100%;
    min-height: 100%;

    > div {
      display: flex;
      flex-direction: column;
      padding: 10px;
      margin-bottom: 15px;
      position: relative;

      &:first-child {
        margin-top: 10px;
      }

      &.chatbot__handle{
        flex-direction: row;
        &:not(.handling) {
          padding: 0;
          margin-bottom: 0;
        }
      }
    }

    &.woman{
      .chatbot__response::before,
      .chatbot__handle::before{
        background-image: url(/wp-content/themes/noyau/assets/images/chatbot/avatar-femme.svg);
      }
    }
    &.man{
      .chatbot__response::before,
      .chatbot__handle::before{
        background-image: url(/wp-content/themes/noyau/assets/images/chatbot/avatar-homme.svg);
      }
    }
    &.robot{
      .chatbot__response::before,
      .chatbot__handle::before{
        background-image: url(/wp-content/themes/noyau/assets/images/chatbot/avatar-robot.svg);
      }
    }
  }

  &__response,
  &__handle.handling{
    background: $color-light;
    border-radius: $chatbot-radius $chatbot-radius $chatbot-radius 0;
    margin-left: 25px;
    margin-right: 15px;

    &::before {
      content: '';
      display: flex;
      width: 20px;
      height: 20px;
      position: absolute;
      background-image: url(/wp-content/themes/noyau/assets/images/chatbot/avatar-bulles.svg);
      background-size: 100%;
      background-color: $color-light;
      background-position: center;
      background-repeat: no-repeat;
      left: -25px;
      bottom: 0;
      border-radius: 50%;
    }
  }

  &__response{
    a:not(.button) {
      display: inline-block;
      text-decoration: underline;
      color: $color-main;
      margin-bottom: 0;

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }
    }
  }

  &__button-container{
    display: flex;
    flex-wrap: wrap;

    .button {
      margin: 10px 5px 0 0;
      padding: 10px 15px;
      font-size: 12px;
    }
  }

  &__question{
    background: $chatbot-question-bg;
    color: $chatbot-question-color;
    border-radius: $chatbot-radius $chatbot-radius 0 $chatbot-radius;
    margin-left: 40px;
  }

  &__handle {
    &.handling {
      display: flex;
      width: 52px;
      height: 28px;
      justify-content: space-between;
      span{
        width: 8px;
        height: 8px;
        background: $color-gray;
        border-radius: 50%;
        animation: chatbotLoading 1s infinite linear;
        &:nth-child(2) {
          animation: chatbotLoadingBefore 1s infinite linear;
      }
        &:nth-child(3) {
          animation: chatbotLoadingAfter 1s infinite linear;
      }
      }
    }
  }

  @keyframes chatbotLoading {
    0% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(-5px);
    }
    50% {
      transform: translateY(0);
    }
    75% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes chatbotLoadingBefore {
    0% {
      transform: translateY(0);
    }
    25% {
      transform:  translateY(0);
    }
    50% {
      transform:translateY(-5px);
    }
    75% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes chatbotLoadingAfter {
    0% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(0);
    }
    75% {
      transform: translateY(-5px);
    }
    100% {
      transform: translateY(0);
    }
  }

  &__footer{
    display: flex;
    background: $chatbot-footer-bg;
    width: 100%;
    height: 70px;
    padding: 15px;
    align-items: center;
    box-shadow: $chatbot-footer-shadow;
    border-bottom-right-radius: $chatbot-radius;
    border-bottom-left-radius: $chatbot-radius;

    > button {
      display: flex;
      justify-content: center;
      flex: 1;
        svg{
          fill:$color-gray;
        }
        &:hover,
        &:focus,
        &:active{
          svg{
            fill:$color-main;
          }
        }
    }
  }
  &__input-container {
    display: flex;
    width: 255px;
    background: $color-white;
    border-radius: $border-radius;
    border: 1px solid $color-gray;
    height: 40px;
    align-items: center;
    padding: 0 10px;

    input {
      border: 0;
      height: 100%;
    }
    button {
      svg{
        width: 24px;
        height: 24px;
        fill:$color-gray;
      }
      &:hover,
      &:focus,
      &:active{
        svg{
          fill:$color-main;
        }
      }
    }
  }

  &__formreset-container{
    display: none;
    position: absolute;
    height: calc(100% - 70px);
    background: $chatbot-formcontainer-bg;
    bottom: 0;
    left: 0;
    border-bottom-left-radius: $chatbot-radius;
    border-bottom-right-radius: $chatbot-radius;
    width: 100%;

    &.visible{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > button {
        display: flex;
        justify-content: center;
        padding: 10px 20px;
        border: $btn-border-width $btn-border-style $color-white;
        border-radius: $btn-border-radius;
        margin: 10px 0;
        width: 180px;

        &:hover,
        &:focus,
        &:active{
          background: $color-white;
          color: $color-main;
        }
      }

      > span {
        color: $color-white;
        text-transform: uppercase;
        margin: 0 0 10px;
      }
    }
  }
}

// 640
@media screen and (max-width: $small) {
  .chatbot{
    width: 300px;
    height:560px;
    &.collapse {
      width: 60px;
      height: 60px;
    }
    &__header{
      height:60px;
      padding: 0 10px;
    }
    &__title{
      p{
        font-size: $font-size;
      }
    }
    &__container{
      height: calc(100% - 120px);
    }
    &__footer{
      height: 60px;
      padding: 10px 0 10px 10px;
    }
  }
}
