.breadcrumb {
    position: relative;
    z-index: 2;
    width: fit-content;
    margin-top: -30px;
    margin-bottom: 30px;
    padding: 18px 30px;
    background-color: $color-white;
    border-radius: $border-radius;
    box-shadow: $shadow;
    

    ul  {
        display: flex;
        flex-wrap: wrap;
    }

    li {
        display: flex;
        align-items: center;
        color: $color-text;

        &:first-child { 
            padding-left: 0;
        }

        a {
            color: $color-text;
            
            &:hover, &:focus {
                color: $color-main;
                text-decoration: underline;
            }
        }

        svg {
            width: 26px;
            height: 26px;
            margin: 0 10px;
            fill: $color-text;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        position: relative;

        ul {
            min-height: 40px;
        }

        li {
            min-height: 40px;
        } 
    }

}
