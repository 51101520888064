//======================================================================================================
// Colors
//======================================================================================================

// SVG icons
$co-color-main                          : $color-main; 
$co-color-second                        : $color-second;
$co-color-light                         : #d3e6ea;

// Text
$co-color-title                         : $color-main;

// List
$co-list-style                          : $color-main;
