.nav-main {
    &__close {
        width: 40px;
        height: 40px;
        display: none;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border: 2px solid $color-white;
        border-radius: $border-radius--round;
        padding: 10px;
        margin: 0;
        margin-left: auto;
        transition: $duration;

        svg {
            width: 12px;
            height: 12px;
            fill: $color-white;
            transition: $duration;
        }

        &:hover,
        &:focus {
            background-color: $color-white;
            border-color: $color-white;

            svg {
                fill: $color-dark;
            }
        }
    }

    .menu__nav__list {
        display: flex;
        transition: $duration ease-out;
    } 

    .nav-main-item {
        text-align: center;
        transition: all $duration ease-in-out;

        &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {

            .nav-main-link {
                &::after {
                    width: 50px;
                }
            }
            
            .sub-menu {
                display: flex;
                justify-content: center;
            }
        }

        &:first-of-type {
            border-radius: 30px 0 0 30px;

            .nav-main-link { 
                border-radius: 30px 0 0 30px;
                background-color: $color-second;
            }
        }

        .nav-main-link {
            position: relative;
            display: flex;
            align-items: center;
            height: 90px;
            padding: 35px 32px;
            border-right: 1px solid rgba(137, 215, 231, 0.3);
            font-family: $font-family--heading;
            font-size: $font-size--5;
            font-weight: $font-weight-semibold;
            color: $color-text;

            &::after {
                content: '';
                position: absolute;
                bottom: 20px;
                left: 50%;
                width: 0;
                height: 3px;
                transform: translateX(-50%);
                background-color: $color-dark;
                transition: width $duration ease-in-out;
            }
        }

        &:hover, &:focus {
            background-color: $color-main--light;
            transition: all $duration ease-in-out;

            &:first-of-type {
                .nav-main-link { 
                    background-color: $color-main--light;
                }
            }

            .nav-main-link {
                &::after {
                    width: 50px;
                }
            }
            
            + li {
                .nav-main-link {
                    border-left-color: $color-bg--transparent;
                }
            }

        }

        .sub-menu {
            display: none;
            position: absolute;
            top: 90px;
            right: 0;
            width: 100%;
            padding: 30px 0 30px 0;
            z-index: -1;

            &::after {
                content: '';
                position: absolute;
                z-index: -1;
                top: -50px;
                bottom: 0;
                left: -50vw;
                right: -50vw;
                background-color: $color-main;
            }

            &__close {
                width: 40px;
                height: 40px;
                flex-shrink: 0;
                display: none;
                justify-content: center;
                align-items: center;
                background-color: transparent;
                border: 2px solid $color-white;
                border-radius: $border-radius--round;
                padding: 0;
                margin: 0;
                transition: $duration;

                &:hover,
                &:focus {
                    background-color: $color-white;
                    border-color: $color-white;

                    svg {
                        stroke: $color-dark;
                    }
                }

                svg {
                    width: 40px;
                    height: 40px;
                    fill: none;
                    stroke: $color-white;
                    stroke-width: 2px;
                    transition: $duration;
                }
            }

            ul {
                width: 100%;
                column-count: 3;
                column-gap: 30px;
            }

            .subnav {

                &__child, &__grandchild__item {
                    display: inline-flex;
                    width: 100%;
                    margin: -1px;
                    margin: 0 0 10px;
                    padding: 0;
                    line-height: initial;
                    border: 1px solid rgb(255 255 255 / 30%);
                    border-radius: 4px;
    
                    a {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        padding: 13px 10px;
                        border-radius: 4px;
                        color: $color-white;
                        font-family: $font-family;
                        font-weight: $font-weight-bold;
                        font-size: $font-size--text-small;
                        text-align: left;
    
                        &::after {
                            content: '\f345';
                            font: normal 20px/1 dashicons;
                            display: inline-block;
                            margin-left: 10px;
                            color: $color-white;
                            opacity: .5;
                            transition: all $duration;
                        }
    
                        &:hover, &:focus {
                            background-color: $color-third;
                            color: $color-dark;
    
                            &::after {
                                color: $color-dark;
                                opacity: 1;
                            }
                        }
                    }
                }

                &__child {
                    &--grandchild {
                        .subnav__grandchild, 
                        .subnav__child {
                            border: 0;
                        }
                    }
                }

                &__grandchild {
                    display: flex;
                    flex-direction: column;

                    &__item {
                        display: flex;
                        align-items: center;
                        width: calc(100% - 20px);
                        margin: 0 10px;
                        border-radius: 0;

                        &:nth-child(2) {
                            border-top-right-radius: 4px;
                            border-top-left-radius: 4px;

                            a {
                                border-top-right-radius: 4px;
                                border-top-left-radius: 4px;
                            }
                        }

                        &:last-child {
                            border-bottom-right-radius: 4px;
                            border-bottom-left-radius: 4px;
                            margin-bottom: 10px;

                            a {
                                border-bottom-right-radius: 4px;
                                border-bottom-left-radius: 4px;
                            }
                        }
    
                        a {
                            font-weight: $font-weight;
                            padding: 14px 18px;
                            border-radius: 0;
                        }
                    }
    
                }
                
            }

        }
    }
}


// Responsive button
.nav-main_button {
    display: none;
}
// Responsive button label
.nav-main_icon {
    position: relative;
    display: none;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    font-family: $font-family--heading;
    font-size: $font-size--4;
    font-weight: $font-weight-semibold; 
    color: $color-text;
    background-color: transparent;
    margin: 0;
    padding: 30px;
    border-right: 1px solid rgba(137, 215, 231, 0.3);
    transition: $duration;

    .navicon {
        width: 18px;
        height: 2px;
        position: relative;
        top: -1px;
        display: block;
        background: $color-dark;
        transition: $duration;

        &:before, 
        &:after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            display: block;
            background: $color-dark;
            transition: $duration;
        }

        &:before {
            top: 6px;
        }

        &:after {
            top: -6px;
        } 
    }
}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    #nav-main .nav-main-item .nav-main-link {
        padding: 29px 19px;
    }

}


// 960
@media screen and (max-width: $medium) {
    body.admin-bar .nav-main__content {
        top: 32px;
    }

    .nav-main {   
        &--active {
            .nav-main__content {
                right: 0;
            }
        }
        
        &__content {
            position: fixed;
            top: 0;
            right: 100%;
            z-index: 990;
            display: flex;
            flex-direction: column;
            row-gap: 30px;
            width: 100%;
            height: 100vh;
            background-color: $color-main;
            padding: 50px 30px;
            transition: $duration;
        }

        &__close {
            display: flex;
        }

        .menu__nav__list {
            flex-direction: column;
        }

        .nav-main-item {
            width: 100%!important;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid rgb(255 255 255 / 30%);

            &::after {
                content: '\f345';
                font: normal 20px/1 dashicons;
                color: $color-white;
                transition: all $duration;
            }

            &.menu-item-has-children {

                &:focus-within .sub-menu, 
                &:hover .sub-menu {
                    justify-content: flex-start;
                }

                &.active {   
                    .sub-menu {
                        right: 0;
                    }
                }
            }

            &:first-of-type {
                border-radius: 0;

                .nav-main-link {
                    border-radius: 0;
                    background-color: $color-bg--transparent;
                }

                &:hover, &:focus {
                    .nav-main-link {
                        background-color: $color-bg--transparent;
                    }
                }
            }

            &:hover, &:focus {
                background-color: $color-bg--transparent;
            }

            .nav-main-link {
                justify-content: flex-start !important;
                height: auto;
                padding: 20px 0;
                border: 0;
                color: $color-white;
                background-color: $color-bg--transparent;

                &::after {
                    display: none;
                }

                &:hover, &:focus {
                    background-color: $color-bg--transparent;
                }
            }

            .sub-menu {
                top: 0;
                right: 100%;
                z-index: 1;
                display: flex;
                flex-direction: column;
                row-gap: 30px;
                width: 100vw;
                height: 100vh;
                padding: 50px 0;
                background-color: $color-main;
                transition: $duration;

                &::after {
                    display: none;
                }

                &__close {
                    display: flex;
                    margin-left: 30px;
                }

                ul {
                    max-width: 100%;
                    display: flex;
                    flex-direction: column;
                    padding: 0 30px; 
                    overflow-y: auto;
                    
                }

                .subnav__child--grandchild .subnav__child, 
                .subnav__child--grandchild .subnav__grandchild {
                    padding: 0;
                }
            }
        }

        // Responsive button label
        .nav-main_icon {
            display: flex;
        }
    }
    
}


// 640
@media screen and (max-width: $small) {

    .nav-main_icon {
        padding: 30px 25px;
    }

}
