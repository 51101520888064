#mapSide {
    background: $color-light;
    > .description {
        color: $color-gray;
        font-style: italic;
        line-height: 1.2;
        margin: 0 0 10px;
    }
    .report-popup{
        &__category {
            display: inline-flex;
            font-size: .813rem;
            color: $color--taxo;
            text-transform: uppercase;
            background: $color-white;
            padding: 3px 15px;
            border-radius: 20px;
        }

        &__title{
            font-size: $font-size--4;
            margin:10px 0;
        }

        &__details-group{
            margin: 0 0 20px;
        }

        &__location,
        &__date{
            margin: 0 0 5px;
            font-style: italic;
        }

        &__status{
            font-weight: $font-weight-bold;
            margin: 0 0 5px;
            &::before {
                font: 400 18px/1 dashicons;
                border-radius: 50%;
                padding: 2px;
                background: red;
                margin: 5px 5px 0 0;
                top: 3px;
                position: relative;
                color:#fff;
            }

            &.in_process {
                &::before {
                    content: "\f469";
                    background: #fc7103;
                    padding: 2px 2px 2px 3px;
                }
            }
            &.processed {
                &::before {
                    content: "\f147";
                    background: #0c810a;
                }
            }
            &.unprocessable {
                &::before {
                    content: "\f335";
                    background: #dd1800;
                }
            }

        }
    }
}

#prop_reports{

    .acf-taxonomy-field{
        ul{
            li{
                font-size: inherit;
                margin: 0 0 5px;
                label{
                    font-weight:$font-weight;
                }
                input{
                    margin-top:-3px;
                }
            }
        }
    }
    .acf-field[data-name="report_details_group"]{
        margin: 0;

        >.acf-label {
            display: none;
        }
    }
}


.wrapper_reportmap{
    > .description {
        color: $color-gray;
        font-style: italic;
        line-height: 1.2;
        margin: 0 0 10px;
    }
}


@media screen and (max-width: $large) {
    .wrapper_mapReports {
        #mapReports {
            z-index: 0;
            width: calc(100% - 370px);
            height: 520px;
        }

        #mapSide {
            width: 370px;
            height: 520px;
            padding: 20px;
        }
    }
}

@media screen and (max-width: $medium) {
    .wrapper_mapReports {
        flex-direction: column-reverse;
        #mapReports {
            z-index: 0;
            width: 100%;
            height: 420px;
        }
        #mapSide{
            width: 100%;
            height: auto;
        }
    }


}

// 640
@media screen and (max-width: $small) {
    .wrapper_mapReports {
        #mapReports {
            height: 400px;
        }

        .report-popup {
            &__image-wrapper {
                height: 150px;
            }
        }
    }

}
