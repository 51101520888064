.contact-details-v1 {

    // Grid
    .contact-grid {
        position: relative;
        display: flex;
        align-items: stretch;
    }
    .contact-col {
        flex: 1 1 0;
        max-width: 370px;
        margin-left: 30px;

        &:first-child {
            margin-left: 0;
        }
    }

    // Component card
    .contact-card {
        padding: 30px;
        background-color: $color-bg--neutral;
        border-radius: $border-radius;
        overflow: hidden;

        &__list {
            padding: 30px;

            li {
                padding: 10px 0;

                &:first-child {
                    padding: 0 0 10px 0;
                }

                &:last-child {
                    padding: 10px 0 0 0;
                }
            }
        }

        &__title {
            margin: 0;
            padding: 0;
            border: 0;
            font-family: $font-family--heading;
            font-size: $font-size--4;
            font-weight: $font-weight--heading;
            line-height: $line-height--heading;

            &--padding-bot {
                padding-bottom: 20px;
            }

            &::after {
                display: none;
            }
        }

        // Style to change into button--secondary
        &__button {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            padding: 15px;
            background-color: $btn-second-bg;
            border: $btn-border-width $btn-border-style $btn-second-border-color;
            border-radius: 0 0 $border-radius $border-radius;
            font-family: $btn-font-family;
            font-weight: $btn-font-weight;
            font-size: $btn-font-size;
            line-height: $btn-line-height;
            text-transform: $btn-text-transform;
            text-decoration: none;
            color: $btn-second-color;

            &:hover, &:focus {
                background-color: $btn-second-bg--hover;
                border-color: $btn-second-border-color--hover;
                color: $btn-second-color--hover;
            }
        }

        &--no-padding {
            padding: 0;
        }

        &--column {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: stretch;
            justify-content: space-between; 
        }

    }

    // Component card map
    .contact-card-map {
        min-height: 240px;

        .bloc-map {
            height: 100%;
            margin: 0;
        }

        .wrapper_map {
            height: 100%;
        }

        .leaflet-marker-icon.leaflet-interactive {
            pointer-events: none;
        }

        .leaflet-control-layers, .leaflet-top {
            display: none;
        }

        &--no-schedule {
            flex: 2 1 0;
            max-width: initial;
        }
    }

    // Component buttons
    .contact-buttons {
        .button {
            margin-right: 20px;
        }
    }

}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .contact-details-v1 {

        // Grid
        .contact-col {
            max-width: 293px;
        }

        // Component card map
        .contact-card-map {
            &--no-schedule {
                max-width: initial;
            }
        }

    }

}


// 960
@media screen and (max-width: $medium) {

    .contact-details-v1 {

        // Grid
        .contact-grid {
            flex-wrap: wrap;
        }
        .contact-col {
            flex: 1 1 50%;
            max-width: 295px;
        }

        // Component card map
        .contact-card-map {
            flex: 1 1 100%;
            order: 1;
            max-width: initial;
            margin-left: 0;
            margin-top: 30px;

            &--no-schedule {
                flex: 1 1 50%;
                max-width: 295px;
                margin-top: 0;
                margin-left: 30px; 
            }

        }

    }

}


// 640
@media screen and (max-width: $small) {

    .contact-details-v1 {

        // Grid
        .contact-col {
            flex: auto;
            max-width: 300px;
            margin: 15px 0;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        // Component card map
        .contact-card-map {
            flex: auto;
            order: 0;
            max-width: 300px;
            width: 300px;
            margin-left: 0;
            margin-top: 15px;

            &--no-schedule {
                flex: auto;
                max-width: 300px;
                width: 300px;
                margin-top: 15px;
                margin-left: 0; 
            }

        }

    }

}
