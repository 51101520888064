.cover {
    position: relative;

    &__content {
        display: flex;
        align-items: stretch;
        margin-top: -50px;

        &--not-img {
            .cover__informations {
                width: 100%;
                padding: 100px calc((100% - 1170px) / 2) 85px calc((100% - 1170px) / 2);  
            }
        }
    }

    &__image-wrapper {
        width: 50%;
        min-height: 400px;
        background-color: $color-bg--image;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 50%;
        padding: 80px calc((100% - 1170px) / 2) 80px 50px;
        background-color: $color-bg--neutral;
        color: $color-text;       
    }

    &__title {
        position: relative;
        margin: 30px 0 10px 0;
        padding-bottom: 10px;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 85px;
            height: 3px;
            background-color: $color-main;
            border-radius: 10px;
        }
    }

    &__lead-wrapper {
        p {
            margin: 20px 0 0;
            font-weight: $font-weight;
            font-size: 1.125rem;
        }
    }

}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .cover {
        &__informations {
            padding: 50px calc((100% - 940px) / 2) 50px 50px;  
        }

        &__content {    
            &--not-img {
                .cover__informations {
                    padding: 80px calc((100% - 940px) / 2) 70px calc((100% - 940px) / 2);  
                }
            }
        }

        
    }
}


// 960
@media screen and (max-width: $medium) {

    .cover {
        &__content {
            flex-wrap: wrap;
            margin-top: -40px;

            &--not-img {
                .cover__informations {
                    padding: 80px 50px;
                }
            }
        }

        &__informations {
            width: 100%;
            padding: 50px 50px 80px 50px;
            align-items: center;
        }

        &__image-wrapper {
            width: 100%;
            height: 350px;
            min-height: 350px;
            padding-top: 0;
        }

        &__title {
            margin-top: 0;
        }

        &__title, &__lead-wrapper, &__intro, &__category {
            width: 620px;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {
        &__lead-wrapper {
            p {
                font-weight: $font-weight;
            }
        }
        
        &__image-wrapper {
            height: 255px;
            min-height: 255px;
        }

        &__title, &__lead-wrapper, &__intro, &__category {
            width: 300px;
        }
    }

}
